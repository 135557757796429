body {
  margin: 0;
  font-family: "Inter", system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3f3e3e;
}

* {
  font-family: "Inter", system-ui;
}

.wrapper {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  max-width: 1080px;
  margin: 50px auto;
}

header {
  border-bottom: 1px solid #eaeaea;
  img {
    margin-right: 15px;
  }

  h3,
  p {
    margin: 0;
  }
}

.content {
  h1 {
    margin: 0;
  }
}

.cartEmpty {
  text-align: center;

  p {
    width: 280px;
    line-height: 24px;
  }

  .greenButton {
    width: 245px;
    margin-top: 20px;

    &:hover {
      img {
        transform: rotate(180deg) translateX(3px);
      }
    }

    img {
      position: relative;
      top: 1px;
      transform: rotate(180deg);
      margin-right: 15px;
      transition: transform 0.15s ease-in-out;
    }
  }
}

.search-block {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  padding: 0 15px;
  position: relative;

  .clear {
    position: absolute;
    right: 0;
    width: 18px;
    height: 18px;
    top: 13px;
    right: 15px;
  }

  input {
    border: 0;
    padding: 13px;
    font-size: 16px;
    width: 200px;
  }
}





.cartTotalBlock {
  ul {
    display: block;
    margin-bottom: 40px !important;

    li {
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;

      div {
        flex: 1;
        height: 1px;
        border-bottom: 1px dashed #dfdfdf;
        position: relative;
        top: -4px;
        margin: 0 7px;
      }
    }
  }
  .greenButton {
    position: relative;

    &:disabled {
      background-color: #d63434;
      cursor: default;
      animation: button-loading 0.6s ease-in-out infinite;
    }

    &:hover {
      img {
        transform: translateX(10px);
      }
    }

    img {
      position: absolute;
      right: 30px;
      top: 20px;
      transition: transform 0.2s ease-in-out;
    }
  }
}

.greenButton {
  width: 100%;
  height: 55px;
  background: #608034;
  border-radius: 18px;
  border: 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:disabled {
    background-color: #bebebe !important;
    cursor: default;
  }

  &:hover {
    background: lighten($color: #4a6e18, $amount: 10%);
  }
  &:active {
    background: darken($color: #294108, $amount: 5%);
  }
}

.cartItem {
  border: 1px solid #f3f3f3;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px;

  .cartItemImg {
    height: 70px;
    width: 70px;
    background-size: contain;
    background-position: 0 10px;
    background-repeat: no-repeat;
    margin-right: 20px;
  }
  p {
    font-size: 16px;
  }

  b {
    font-size: 14px;
  }

  .removeBtn {
    opacity: 0.4;
    cursor: pointer;
    transition: opacity 0.15s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}

@keyframes button-loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
